
import 'reflect-metadata'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import Dark from '@/mixins/dark'
import Connectivity from '@/mixins/connectity.mixin'
import { getApp } from '@/helpers/feathers'
import WidgetBasic from '@/components/WidgetBasic/WidgetBasic.vue'
import ProgressCircle from '@/components/ProgressCircle/ProgressCircle.vue'
import ActionButton from '@/components/ActionButton/ActionButton.vue'
import KeyNumber from '@/components/KeyNumber/KeyNumber.vue'
import AddDashboardElement from '@/components/AddDashboardElement/AddDashboardElement.vue'
import WidgetEditor from '@/components/WidgetEditor/WidgetEditor.vue'
import { Query } from '@feathersjs/feathers'
import Draggable from 'vuedraggable'
import EditableText from '@/components/EditableText/EditableText.vue'
import {
  WidgetDto,
  ValueTypes,
  Translation
} from '@/components/WidgetEditor/WidgetEditor.schema'
import MultiLanguageEditor from '@/components/MultiLanguageEditor/MultiLanguageEditor.vue'
import store from '@/store'
import { LicenseData } from '../../interfaces/licenseData.schema'

/**
 * Das Dashboard mit Kategorien, Reihen und Widgets
 * Provisorische Lösung bis Interfaces in eigenes Repo ausgelagert werden
 */
interface Dashboard {
  /**
   * Gibt den Namen des Rollendashboards zurück.
   */
  dashboardName?: string

  /**
   * Die Kategorien des Dashboards
   */
  categories: Categories[]

  /**
   * Die Übersetzungen des Dashboard-Namens
   */
  dashboardTranslations?: DashboardTranslation[]
}

/**
 * Übersetzungsdaten
 */
interface DashboardTranslation {
  /**
   * Sprachschlüssel
   */
  language?: string

  /**
   * Übersetzung
   */
  translation?: string
}

/**
 * Die Kategorien mit den Reihen und Widgets
 * Provisorische Lösung bis Interfaces in eigenes Repo ausgelagert werden
 */
interface Categories {
  /**
   * Die Rows der Kategorie
   * Provisorische Lösung bis Interfaces in eigenes Repo ausgelagert werden
   */
  rows: Rows[]

  /**
   * Die ID der Kategorie
   */
  id: number
}

/**
 * Die Rows mit den Widgets
 */
interface Rows {
  /**
   * ID der Row
   */
  id: number

  /**
   * ID der Kategorie, der die Row angehört
   */
  categoryID: number

  /**
   * Größe der Row
   */
  size: number

  /**
   * Bezeichnung der Row
   */
  name: string

  /**
   * Die Widgets der Row
   * Provisorische Lösung bis Interfaces in eigenes Repo ausgelagert werden
   */
  widgets: Widgets[]

  /**
   * Die Position der Row
   */
  position: number
}

/**
 * Das Widget mit den Optionen
 * Provisorische Lösung bis Interfaces in eigenes Repo ausgelagert werden
 */
interface Widgets {
  /**
   * ID des Widgets
   */
  id: number

  /**
   * Die Optionen des Widgets
   * Provisorische Lösung bis Interfaces in eigenes Repo ausgelagert werden
   */
  options: Options

  /**
   * Position des Widgets
   */
  position: number

  /**
   * ID der Zeile innerhalb der Dashboard-Kategorie.
   */
  rowID: number

  /**
   * Aktvititätsstatus des Widgets.
   */
  active: boolean
}

/**
 * Provisorische Lösung bis Interfaces in eigenes Repo ausgelagert werden
 * Die Optionen für die Widgets
 */
interface Options {
  /**
   * Beschreibung des Widgets
   * Provisorische Lösung bis Interfaces in eigenes Repo ausgelagert werden
   */
  description?: string

  /**
   * Zähler des dargestellten Bruchs
   */
  numerator?: string

  /**
   * Nenner des dargtestellten Bruchs
   */
  denominator?: string

  /**
   * Übersetzungen der Beschreibung
   */
  descriptionTranslations?: Translation[]

  /**
   * Schaltflächenbeschreibung
   **/
  actionButtonLabel?: string

  /**
   * Übersetzungen der Schaltflächenbeschreibung
   */
  actionButtonLabelTranslations?: Translation[]
}

/**
 * Event-Daten, die nach dem Verschieben eines Widgets zur Verfügung stehen.
 */
type EndEvent = {
  /**
   * Ziel-Reihe.
   */
  to: {
    /**
     * ID der Reihe.
     */
    id: string
  }

  /**
   * Element, welches verschoben wurde.
   */
  item: {
    /**
     * ID des Elements.
     */
    id: number
  }

  /**
   * Index im neuen Array.
   */
  newDraggableIndex: number
}

/**
 * Rendert ein Dashboard mit Kategorien, Reihen und Widgets
 */
@Component({
  components: {
    WidgetBasic,
    ProgressCircle,
    KeyNumber,
    ActionButton,
    AddDashboardElement,
    WidgetEditor,
    Draggable,
    EditableText,
    MultiLanguageEditor
  }
})
export default class DashboardViewer extends Mixins(Dark, Connectivity) {
  /**
   * ID des Dashboards.
   */
  @Prop({ type: Number, required: true })
  public id!: number

  /**
   * Aktiviert die Editor-Ansicht.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public editMode!: boolean

  /**
   * Zeigt den Widget Editor an, um ein neues Widget anzulegen.
   */
  public showCreateWidgetDialog = false

  /**
   * Dashboard lädt, wenn true
   */
  public loading = true

  /**
   * Komplettes Dashboard-Objekt mit Kategorien, Zeilen und Widgets
   */
  public data?: Dashboard

  /**
   * Zeigt an, ob die Lizenzfehlermeldung zu sehen ist
   */
  public showLicenseError = false

  /**
   * Fügt dem Dashboard eine neu hinzugefügte Zeile hinzu
   *
   * @param newRow - Array mit Objekten, welche die Informationen über die neue
   * Zeile aus dem Service beinhalten
   */
  public addDashboardRow(newRow: Rows): void {
    if (this.data) {
      const categories = this.data.categories
      const row = {
        categoryID: newRow.categoryID,
        id: newRow.id,
        position: newRow.position,
        name: newRow.name,
        size: newRow.size,
        widgets: []
      }

      if (categories.length > 1) {
        const categoryIndex = this.data.categories.findIndex(
          (element): boolean => element.id === newRow.categoryID
        )

        const category = this.data.categories[categoryIndex]

        category.rows.push(row)
        this.$forceUpdate()
      } else {
        categories[0].rows.push(row)
        this.$forceUpdate()
      }
    }
  }

  /**
   * Triggered die Funktion, die das Dashboard datenbankseitig anfragt
   */
  public async created(): Promise<void> {
    try {
      await this.getDashboard(this.id)
      await this.license()
    } catch (error) {
      throw new Error(error)
    }
  }

  /**
   * Holt sich ein Dashboard mit einer bestimmten ID und speichert das Ergebnis
   * in data
   *
   * @param id - ID des Dashboards
   */
  public async getDashboard(id: number): Promise<void> {
    try {
      let query = {} as Query
      if (this.editMode) {
        query = {
          $dashboardEditMode: true
        }
      }

      const dashboard: Dashboard = await (await getApp())
        .service('dashboard')
        .get(id, { query })

      let noWidgets = true

      if (
        !this.editMode &&
        dashboard.categories.length > 0 &&
        dashboard.categories[0].rows.length > 0
      ) {
        // Es alle Rows auf Widgets überprüft
        for (const row of dashboard.categories[0].rows) {
          if (row.widgets.length > 0) {
            noWidgets = false
            break
          }
        }
      }

      if (!this.editMode && noWidgets) {
        this.loading = false
        return
      }

      this.data = dashboard

      this.loading = false
    } catch (error) {
      throw new Error(error)
    }
  }

  /**
   * Fügt den bereits geladenen Widget-Daten ein neues Widget hinzu.
   *
   * @param newWidget - Daten des neu angelegten Widgets
   */
  public addNewWidgetToRow(newWidget: Widgets): void {
    if (!this.data) {
      return
    }

    const { rows } = this.data.categories[0]

    const rowIndex = rows.findIndex(
      (row: Rows): boolean => row.id === newWidget.rowID
    )

    rows[rowIndex].widgets.push(newWidget)

    this.$forceUpdate()
  }

  /**
   * Entfernt eine Row aus den bereits geladenen Daten.
   *
   * @param row - gelöschte Reihe.
   */
  public removeDashboardRow(row: Rows): void {
    if (!this.data || !this.data.categories[0]) {
      return
    }

    const { rows } = this.data.categories[0]

    if (!rows) {
      return
    }

    const rowIndex = rows.findIndex((r: Rows): boolean => r.id === row.id)

    if (rowIndex > -1) {
      this.data.categories[0].rows.splice(rowIndex, 1)

      this.$forceUpdate()
    }
  }

  /**
   * Aktualisiert eine Row nach dem Entfernen eines Widgets
   *
   * @param rowID - ID der Row in der das entfernte Widget enthalten war
   * @param widgetID - ID des entfernten Widgets
   */
  public removeWidget(rowID: number, widgetID: number): void {
    if (this.data) {
      const category = this.data.categories[0]

      const searchedRowID = category.rows.findIndex(
        (row: Rows): boolean => row.id === rowID
      )

      if (searchedRowID === -1) {
        throw new Error(`Row #${searchedRowID} cannot be found in data!`)
      }

      const searchedWidgetID = category.rows[searchedRowID].widgets.findIndex(
        (widget: Widgets): boolean => widget.id === widgetID
      )

      if (searchedWidgetID === -1) {
        throw new Error(`Widget #${searchedWidgetID} cannot be found in data!`)
      }

      this.data.categories[0].rows[searchedRowID].widgets.splice(
        searchedWidgetID,
        1
      )
      for (
        let i: number = searchedWidgetID;
        i < this.data.categories[0].rows[searchedRowID].widgets.length;
        i++
      ) {
        this.data.categories[0].rows[searchedRowID].widgets[i].position = --this
          .data.categories[0].rows[searchedRowID].widgets[i].position
      }
      this.$forceUpdate()
    }
  }

  /**
   * Aktualisiert das übergebene Widget im `data`-Objekt.
   *
   * @param widget - aktualisiertes Widget
   */
  public updateWidget(widget: Widgets[]): void {
    if (!this.data) {
      return
    }

    const [newWidget] = widget
    const { rows } = this.data.categories[0]

    const rowIndex = rows.findIndex(
      (row: Rows): boolean => row.id === newWidget.rowID
    )

    if (rowIndex === -1) {
      throw new Error(`Row #${newWidget.rowID} cannot be found in data!`)
    }

    const widgetIndex = rows[rowIndex].widgets.findIndex(
      (w: Widgets): boolean => w.id === newWidget.id
    )

    if (widgetIndex === -1) {
      throw new Error(`Widget #${newWidget.id} cannot be found in data!`)
    }

    this.data.categories[0].rows[rowIndex].widgets[widgetIndex] = {
      ...this.data.categories[0].rows[rowIndex].widgets[widgetIndex],
      ...newWidget
    }

    this.$forceUpdate()
  }

  /**
   * Prüft, ob Lizenzwerte hinterlegt sind und setzt die Variable
   * zur Steuereung der Fehlermeldug entsprechend
   */
  public async license(): Promise<void> {
    try {
      let licenseValue = localStorage.getItem('license')
      if (!licenseValue) {
        const license: LicenseData = await (await getApp())
          .service('license')
          .find()

        localStorage.setItem('license', license.valid ? '1' : '0')
        localStorage.setItem(
          'licenseExpiredDate',
          license.expireDate.toString()
        )
      }
    } catch (error) {
      localStorage.setItem('liense', '-1')
      localStorage.setItem('licenseExpiredDate', '-1')
    }
    this.showLicenseError = !(localStorage.getItem('license') === '1')
  }

  /**
   * Gibt die Fehlermeldung für die ungültige Lizenz zurück
   *
   * @returns Fehlermeldung
   */
  public licenseInvalidText(): string {
    let res
    const licenseExp = localStorage.getItem('licenseExpiredDate') ?? ''

    if (licenseExp === '-1' || licenseExp === '') {
      res = this.$t('system.notification.licenseCheckFailed').toString()
    } else {
      const expirationDate = new Date(licenseExp).toLocaleDateString(
        this.$i18n.locale
      )

      res = this.$t('system.notification.licenseInvalid', {
        expired: expirationDate
      }).toString()
    }

    return res
  }

  /**
   * Gibt anhand des Widget Typen die passende Beschreibung zurück
   *
   * @param widgetType - Der verwendete Widget Typ
   * @param widget - Das Widget dessen Beschreibung geprüft wird
   * @returns Beschreibung als String
   */
  public widgetDescription(widgetType: number, widget: Widgets): string {
    const translation = this.getTranslation('description', widget)
    const description =
      widgetType === 1
        ? translation || this.getDescription(widget)
        : translation

    return description ?? ''
  }

  /**
   * Gibt, abhängig von der gewählten Sprache, die Übersetzung für ein
   * mehrsprachiges Feld zurück
   *
   * @param field - Name des Feldes
   * @param widget - widget
   * @returns Übersetzung
   **/
  public getTranslation(field: string, widget?: Widgets | WidgetDto): string {
    let selectedTranslation
    let translations: Translation[] = []

    switch (field) {
      case 'title':
        if (widget && 'title' in widget) {
          selectedTranslation = (widget as WidgetDto).title || ''
          translations = (widget as WidgetDto).titleTranslations || []
        }
        break
      case 'description':
        if (widget && 'options' in widget) {
          selectedTranslation = (widget as Widgets).options.description || ''
          translations =
            (widget as Widgets).options.descriptionTranslations || []
        }
        break
      case 'actionButtonLabel':
        if (widget && 'options' in widget) {
          selectedTranslation =
            (widget as Widgets).options.actionButtonLabel || ''
          translations =
            (widget as Widgets).options.actionButtonLabelTranslations || []
        }
        break
      default:
        throw new Error(`Field ${field} has no translations`)
        break
    }

    const currentTranslation = translations.find(
      translation => translation.language === this.$i18n.locale
    )

    const fallbackTranslation = translations.find(
      translation => translation.language === this.$i18n.fallbackLocale
    )

    if (currentTranslation) {
      selectedTranslation = currentTranslation.translation
    } else if (fallbackTranslation) {
      selectedTranslation = fallbackTranslation.translation
    }

    return selectedTranslation ?? ''
  }

  /**
   * Setzt die Standardbeschreibung des Widgets basierend auf den Einstellungen
   * zusammen und gibt diese zurück
   *
   * @param widget - Das Widget, für das die Beschreibung erstellt werden soll
   * @returns Standardbeschreibung des Widgets
   */
  public getDescription(widget: Widgets): string {
    const numerator = widget.options.numerator
    const denominator = widget.options.denominator

    let firstText
    let secondText

    if (numerator === ValueTypes.Total) {
      firstText = this.$t('dashboardViewer.widgetDescription.total')
    } else if (numerator === ValueTypes.Done) {
      firstText = this.$t('dashboardViewer.widgetDescription.done')
    } else {
      firstText = this.$t('dashboardViewer.widgetDescription.open')
    }

    if (denominator === ValueTypes.Open) {
      secondText = this.$t('dashboardViewer.widgetDescription.open')
    } else if (denominator === ValueTypes.Done) {
      secondText = this.$t('dashboardViewer.widgetDescription.done')
    } else {
      secondText = this.$t('dashboardViewer.widgetDescription.total')
    }

    return firstText + ' / ' + secondText
  }

  /**
   * Sortiert die Reihenfolge der Dashboard Elemente
   *
   * @returns - Aufbereitetes Dashboard (nach `position` sortiert)
   */
  public get processedData(): Dashboard | undefined {
    if (!this.loading && this.data) {
      this.sortByPosition(this.data.categories[0]['rows'])

      for (const row of this.data.categories[0]['rows']) {
        this.sortByPosition(row['widgets'])
      }
      return this.data
    }
  }

  /**
   * Sortiert ein Array von Objekten nach dem key `position`
   *
   * @param array - Das zu sortierende Array von Objekten
   */
  public sortByPosition(array: Rows[] | Rows['widgets']): void {
    array.sort(function (a: Rows | Widgets, b: Rows | Widgets): number {
      const keyA = a.position
      const keyB = b.position
      if (keyA < keyB) {
        return -1
      }
      if (keyA > keyB) {
        return 1
      }
      return 0
    })
  }

  /**
   * Achtet darauf ob sich die ID des Dashboards ändert, um dieses dann neu zu
   * laden
   */
  @Watch('id')
  public async onDashboardChange(): Promise<void> {
    this.loading = true
    try {
      await this.getDashboard(this.id)
    } catch (error) {
      throw new Error(error)
    }
  }

  /**
   * Beim Bewegen eines Widgets werden die Positionsdaten angepasst.
   *
   * @param e - Daten über das verschobene Widget.
   */
  public async widgetsPositionChanged(e: EndEvent): Promise<void> {
    if (!this.editMode) {
      return
    }

    const id = e.item.id
    const rowID = parseInt(e.to.id, 10)
    const position = e.newDraggableIndex

    const app = await getApp()
    app.service('dashboard-widget').patch(id, { rowID, position })

    this.$forceUpdate()
  }

  /**
   * Der Name der Reihe wird aktualisiert.
   *
   * @param itemRow - die Reihe
   */
  public async updateRowName(itemRow: Rows): Promise<void> {
    const { name } = itemRow

    try {
      await (await getApp()).service('dashboard-row').patch(itemRow.id, {
        name
      })
    } catch (error) {
      throw error
    }
  }

  /**
   * Öffnet den Mehrsprachigkeits-Editor
   *
   * @param openFunction - Funktion aus der Kinderkomponente zum Öffnen des
   * Mehrsprachigkeits-Editors.
   */
  public openMLE(openFunction: Function): void {
    if (this.data) {
      if (this.data.dashboardTranslations) {
        const translation = this.data.dashboardTranslations.find(
          translation => translation.language === this.$i18n.locale
        )
        if (!translation) {
          const translation = {
            language: this.$i18n.locale,
            translation: this.data.dashboardName
          }
          this.data.dashboardTranslations.push(translation)
        }
      } else {
        this.data.dashboardTranslations = []
        const translation = {
          language: this.$i18n.locale,
          translation: this.data.dashboardName
        }
        this.data.dashboardTranslations.push(translation)
      }
      openFunction()
    } else {
      throw new Error('No dashboard data found')
    }
  }

  /**
   * Speichert die über den Mehrsprachigkeitseditor gemachten Änderungen
   * an den Übersetzungen im Dashboard
   *
   * @param translations - Array mit den geänderten Übersetzungsdaten
   */
  public async translationsAdded(
    translations: DashboardTranslation[]
  ): Promise<void> {
    if (this.data && this.data.dashboardTranslations) {
      this.data.dashboardTranslations = translations
      const newDashboardName = this.data.dashboardTranslations.find(
        translation => translation.language === this.$i18n.locale
      )
      if (newDashboardName && newDashboardName.translation) {
        this.data.dashboardName = newDashboardName.translation
        this.$forceUpdate()
      }
      await (await getApp()).service('dashboard').patch(this.id, {
        dashboardTranslations: this.data.dashboardTranslations
      })
      store.commit('dashboardTranslations/addDashboard', {
        id: this.id,
        translations: translations
      })
    } else {
      throw new Error('No dashboard data found')
    }
  }

  /**
   * Gibt den Dashboardnamen in der aktuell gewählten Sprache zurück.
   * Wenn keine Übersetzung in der aktuell gewählten Sprache vorhanden ist,
   * wird die Übersetzung in der Fallback-Sprache verwendet.
   * Ist weder in der gewählten, noch in der Fallbacksprache eine Übersetzung
   * verfügbar, so wird die Rollenbezeichnung verwendet.
   *
   * @returns den Namen des Dashboards
   */
  public getDashboardName(): string {
    let currentTranslation
    let fallbackTranslation

    if (this.data && this.data.dashboardTranslations) {
      currentTranslation = this.data.dashboardTranslations.find(
        translation => translation.language === this.$i18n.locale
      )

      fallbackTranslation = this.data.dashboardTranslations.find(
        translation => translation.language === this.$i18n.fallbackLocale
      )
    }

    let res: string
    if (currentTranslation && currentTranslation.translation) {
      res = currentTranslation.translation
    } else if (fallbackTranslation && fallbackTranslation.translation) {
      res = fallbackTranslation.translation
    } else if (this.data && this.data.dashboardName) {
      res = this.data.dashboardName
    } else {
      throw new Error('No dashboard data found')
    }

    return res
  }
}
