
import { Vue, Prop, Component } from 'vue-property-decorator'

type HTMLElementEvent<T extends HTMLElement> = Event & {
  /**
   * Target, z.B. `HTMLInputElement`
   */
  target: T
}

/**
 * Ein Text, der durch Klicken editierbar wird und verändert werden kann.
 */
@Component({})
export default class EditableText extends Vue {
  /**
   * v-model-Bindung.
   */
  @Prop({ type: String, required: false, default: '' })
  public value!: string

  /**
   * Übergebener Platzhalter-Text, sollte das Value leer sein.
   */
  @Prop({ type: String, required: false, default: '' })
  public placeholder!: string

  /**
   * Maximale Anzahl der Zeichen, die das Input-Feld haben darf.
   */
  @Prop({ type: Number, required: false, default: 255 })
  public maxChars?: number

  /**
   * Lokale Variable, die verändert werden kann.
   */
  public changedValue = ''

  /**
   * Zeigt an, ob der Editiermodus ein- oder ausgeschaltet ist.
   */
  public editMode = false

  /**
   * Created-Funktion.
   */
  public created(): void {
    this.changedValue = this.value ?? ''
  }

  /**
   * Schaltet zwischen Ein- und Ausgabe um.
   */
  public toggleEditMode() {
    this.editMode = !this.editMode

    if (this.editMode) {
      this.$nextTick(() => {
        ;(this.$refs.textfield as HTMLElement).focus()
      })
    }
  }

  /**
   * Handler für das Enter.
   *
   * @param e - Event
   */
  public handleEnter(e: HTMLElementEvent<HTMLInputElement>): void {
    this.handleBlur(e)
  }

  /**
   * Handler für das Verlassen des Eingabefeldes.
   *
   * @param e - Event
   */
  public handleBlur(e: HTMLElementEvent<HTMLInputElement>): void {
    this.toggleEditMode()
    this.emitValue(e.target.value)
  }

  /**
   * Emitted den neuen Wert an die Elternkomponente.
   *
   * @param v - Value
   */
  public emitValue(v: string): void {
    this.$emit('input', v)
  }
}
