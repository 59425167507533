import { Component, Vue } from 'vue-property-decorator'
import { namespace } from 'vuex-class'

const ConnectivityStore = namespace('connectivity')

/**
 * Mixin für Konnektivität.
 */
@Component({})
export default class Connectivity extends Vue {
  /**
   * Zeigt den Online/Offline-Status der App an.
   */
  @ConnectivityStore.Getter('online')
  public isOnline!: boolean
}
