
import { Component, Vue, Prop, Watch } from 'vue-property-decorator'
import CustomDialog from '@/components/CustomDialog/CustomDialog.vue'
import CountryFlag from '@/components/CountryFlag/CountryFlag.vue'
import { Translation } from '@/components/WidgetEditor/WidgetEditor.schema'
import { namespace } from 'vuex-class'
import { CountryCode } from '@/constants/country-code.enum'

const LanguageStore = namespace('language')

/**
 * Mini-Editor für die Mehrsprachigkeit
 */
@Component({
  components: {
    CustomDialog,
    CountryFlag
  }
})
export default class MultiLanguageEditor extends Vue {
  /**
   * Indikator, ob der Mini-Editor offen oder geschlossen ist
   */
  public showDialog = false

  /**
   * Indikator, ob alle Felder korrekt ausgefüllt sind.
   */
  public valid = false

  /**
   * Array mit Übersetzungsdaten aus dem Widget
   */
  @Prop({ required: true })
  public translations?: Translation[]

  /**
   * Array mit Übersetzungsdaten für den Mehrsprachigkeitseditor
   */
  public loadedTranslations: Translation[] = []

  /**
   * Array von aktiven Sprachen
   */
  @LanguageStore.State('active')
  public activeLanguageList!: CountryCode[]

  /**
   * Feld, zu welchem der Dialog gehört
   */
  @Prop({ required: true })
  public field?: string

  /**
   * Öffnet den Dialog. Funktion wird als Slot-Prop an die Elternkomponente
   * gegeben, damit man den Dialog von außen öffnen kann.
   */
  public openDialog(): void {
    this.showDialog = true
  }

  /**
   * Erstellt beim Öffen des Dialogs eine Liste mit Übersetzungsdaten bestehend
   * aus ggf. im Widget schon vorhandenen Daten ergänzt um leere Einträge für
   * Sprachen, welche zwar im System aktiv sind, für die aber noch keine
   * Übersetzungen angegeben sind
   */
  @Watch('showDialog')
  public loadDataOnOpen(): void {
    if (this.showDialog) {
      this.activeLanguageList.forEach(language => {
        const translation = new Translation()
        translation.language = language
        const tl = this.translations
          ? this.translations.find(
              translation => translation.language === language
            )
          : null
        translation.translation = tl ? tl.translation : ''
        this.loadedTranslations.push(translation)
      })
    } else {
      this.clearForm()
    }
  }

  /**
   * Gibt den Titel des Dialogs zurück
   *
   * @returns Titel des Dialogs
   */
  public getEditorTitle(): string {
    let res = ''
    switch (this.field) {
      case 'title':
        res = this.$t('mle.fields.title').toString()
        break
      case 'description':
        res = this.$t('mle.fields.description').toString()
        break
      case 'actionButtonLabel':
        res = this.$t('mle.fields.buttonLabel').toString()
        break
      case 'dashboard':
        res = this.$t('mle.fields.dashboard').toString()
        break
      default:
        throw new Error(`No title found for field "${this.field}"`)
    }
    return res
  }

  /**
   * Speichert die gemachten Änderungen
   */
  public addTranslations(): void {
    if (!this.field) {
      throw new Error('No field to edit Translations for found')
    }

    this.formValidate()

    const editedTranslations: Translation[] = this.loadedTranslations.filter(
      x => x.translation
    )
    if (this.translations) {
      this.translations.forEach(translation => {
        const contains = editedTranslations.find(
          t => t.language === translation.language
        )
        if (!contains) {
          editedTranslations.push({
            language: translation.language,
            translation: ''
          })
        }
      })
    }
    this.$emit('addedTranslations', editedTranslations, this.field)
    this.showDialog = false
  }

  /**
   * Setzt die gemachten Eingaben im Form zurück
   */
  public clearForm(): void {
    this.loadedTranslations = []
    this.resetFormValidation()
  }

  /**
   * Wird ausgeführt, wenn man den Dialog des Mini-Editors schließt,
   * ohne neue Übersetzungen angelegt zu haben
   */
  public cancelDialog(): void {
    this.resetFormValidation()
    this.showDialog = false
  }

  /**
   * Prüft, ob alle Felder innerhalb des Formulars valide sind.
   * Falls nicht, wird eine Meldung ausgegeben und ein Error geworfen.
   */
  private formValidate(): void {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()

    if (!valid) {
      throw new Error('validation failed.')
    }
  }

  /**
   * Resettet die Formvalidation
   */
  public resetFormValidation(): void {
    const form = this.$refs.form as HTMLFormElement
    form.resetValidation()
  }

  /**
   * Validationsregel für den Titel.
   *
   * @returns Array von Validationsfunktionen
   */
  public get fieldForSelectedLanguageRules(): ((v: string) => string | true)[] {
    return [
      (v: string): string | true =>
        !!v || this.$t('mle.validationErrorLocale').toString()
    ]
  }

  /**
   * Validationsregel für den Titel.
   *
   * @returns Array von Validationsfunktionen
   */
  public get fieldForFallbackLanguageRules(): ((v: string) => string | true)[] {
    return [
      (v: string): string | true =>
        !!v || this.$t('mle.validationErrorFallback').toString()
    ]
  }
}
