
import { Component, Mixins, Prop } from 'vue-property-decorator'
import Dark from '@/mixins/dark'

/**
 * Interface das erlaubt eine number als key zu verwenden
 */
interface NumberIndex {
  [key: number]: number
}

/**
 * Rahmen Komponente, die die Skalierung und das Umbruchverhalten von der
 * Bildschirmgröße des Endgeräts abhängig macht. Über einen slot kann die
 * Komponente mit Inhalt gefüllt werden
 */
@Component({})
export default class ColumnCard extends Mixins(Dark) {
  /**
   * Anzahl der Spalten, die die Komponente einnehmen soll
   */
  @Prop({ type: Number, required: false, default: 12 })
  public cols!: number

  /**
   * Höhe der Komponente
   */
  @Prop({ type: Number, required: false, default: null })
  public height!: number

  /**
   * Darstellungsart des Skeleton Loaders
   */
  @Prop({ type: String, required: false, default: 'image' })
  public skeletonLoaderClass!: string

  /**
   * Gibt an, ob sich der Inhalt noch im Ladevorgang befindet
   */
  public loading = false

  /**
   * Gibt die Anzahl der Spalten, die die Komponente bei dem breakpoint sm
   * einnehmen soll zurück
   *
   * @returns Anzahl der Spalten
   */
  public get sm(): number {
    const sizes: NumberIndex = {
      12: 12,
      6: 12,
      4: 6,
      3: 6,
      2: 6
    }
    return sizes[this.cols] || 12
  }

  /**
   * Gibt die Anzahl der Spalten, die die Komponente bei dem breakpoint md
   * einnehmen soll zurück
   *
   * @returns Anzahl der Spalten
   */
  public get md(): number {
    const sizes: NumberIndex = {
      12: 12,
      6: 8,
      4: 6,
      3: 4,
      2: 4
    }
    return sizes[this.cols] || 12
  }

  /**
   * Gibt die Anzahl der Spalten, die die Komponente bei dem breakpoint lg
   * einnehmen soll zurück
   *
   * @returns Anzahl der Spalten
   */
  public get lg(): number {
    const sizes: NumberIndex = {
      12: 12,
      6: 6,
      4: 4,
      3: 3,
      2: 3
    }
    return sizes[this.cols] || 12
  }

  /**
   * Gibt die Anzahl der Spalten, die die Komponente bei dem breakpoint xl
   * einnehmen soll zurück
   *
   * @returns Anzahl der Spalten
   */
  public get xl(): number {
    return this.cols
  }
}
