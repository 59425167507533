
import { Component, Vue, Prop } from 'vue-property-decorator'

/**
 * Zu erhaltendes Objekt welches die Zahlen zum anzeigen der Kennzahlen
 * beinhaltet
 */
interface DataObject {
  /**
   * Stellt die kleinere Zahl da
   */
  count: number | null
  /**
   * Stellt die größere Zahl da
   */
  max?: number
}

/**
 * Kennzahlen
 */
@Component({})
export default class KeyNumber extends Vue {
  /**
   * Daten die zur Anzeige der Kennzahlen benötigt werden
   */
  @Prop({ type: Object, required: true })
  public data!: DataObject

  /**
   * Farbe für die Kennzahlen
   */
  @Prop({ type: String, required: false, default: '#FFFFFF' })
  public color!: string

  /**
   * Stellt die kleinere Zahl da
   */
  public count: number | null = 0

  /**
   * Stellt die größere Zahl da
   */
  public max = 0

  /**
   * Bestimmt die Textgröße des Widgets
   */
  public textSize = 'text-h3'

  /**
   * Bei Änderung der data werden die neuen Werte den entsprechenden
   * Variablen übergeben und die entsprechende Anzeige befüllt
   *
   * @returns boolean
   */
  public get checkData(): boolean {
    if (Object.keys(this.data).length === 0) {
      return true
    }

    if (this.data.max === undefined) {
      this.count = this.data.count
      this.textSizer(this.count)
    } else {
      this.count = this.data.count
      this.max = this.data.max
      this.textSizer(this.max)
    }
    return false
  }

  /**
   * Berechnet die Schriftgröße der Zahlen anhand der Menge der Stellen der Zahl
   *
   * @param textNumber - größe bestimmende Zahl
   */
  public textSizer(textNumber: number | null): void {
    if (textNumber && textNumber > 99 && textNumber < 998) {
      this.textSize = 'text-h4'
    } else if (this.max > 999) {
      this.textSize = 'text-h5'
    }
  }
}
