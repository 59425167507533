
import { Component, Prop, Mixins } from 'vue-property-decorator'
import Connectivity from '../../mixins/connectity.mixin'
import { getApp } from '@/helpers/feathers'
import { namespace } from 'vuex-class'
import { NotificationPayload } from '@/store/notifications/notification-payload.interface'

const NotificationStore = namespace('notification')

/**
 * Type des Elements, welches mit dem Button hinzugefügt werden kann
 */
enum ElementType {
  /**
   * Dashboard Widget
   */
  Widget = 1,

  /**
   * Dashboard Reihe
   */
  Row = 2
}

/**
 * Platzhalter zum Hinzufügen eines Dashboard Elements in den verschiedenen
 * Größen der möglichen Elemente.
 */
@Component({})
export default class AddDashboardElement extends Mixins(Connectivity) {
  /**
   * Art des Elements, welches hinzugefügt werden soll
   */
  @Prop({ type: Number, required: false, default: 1 })
  public element!: ElementType

  /**
   * ID der Kategorie
   */
  @Prop({ type: Number, required: false })
  public categoryId?: number

  /**
   * Zeigt an, ob die Reihe leer ist.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public rowIsEmpty?: boolean

  /**
   * Die ID der Reihe, die gelöscht werden soll.
   */
  @Prop({ type: Number, required: false })
  public rowId?: number

  /**
   * Togglet einen Toaster und zeigt diesen an
   */
  @NotificationStore.Action('toggleToast')
  public toggleToast!: (payload: NotificationPayload) => void

  /**
   * Gibt an, ob grade ein Element hinzugefügt wird und versetzt den Button in
   * den Ladezustand
   */
  public loading = false

  /**
   * Fügt ein neues Element zum Dashbaord hinzu. (Vorerst nur Rows)
   *
   * @param element - Typ des Elements welches hinzugefügt werden soll
   */
  public async addElement(element: ElementType): Promise<void> {
    const data = {
      categoryID: this.categoryId
    }
    this.loading = true
    let toastText = this.$t(
      'addDashboardElement.notification.addRow.success'
    ).toString()
    let toastType = 'success'

    if (element === ElementType.Row) {
      let result
      try {
        result = await (await getApp()).service('dashboard-row').create(data)
      } catch (error) {
        toastText = this.$t(
          'addDashboardElement.notification.addRow.error'
        ).toString()
        toastType = 'error'

        throw error
      } finally {
        this.toggleToast({
          text: toastText,
          type: toastType,
          btnText: '',
          data: {}
        })
        this.loading = false
        this.$emit('add-dashboard-row', result[0])
      }
    } else if (element === ElementType.Widget) {
      this.loading = false
    }
  }

  /**
   * Löscht eine Reihe aus der Datenbank.
   * Das Löschen ist backendseitig nur möglich, wenn die Reihe leer ist.
   *
   * @param rowId - ID der Reihe, die gelöscht werden soll.
   */
  public async removeRow(rowId: number): Promise<void> {
    if (!this.rowIsEmpty) {
      throw Error('row must be empty to delete it!')
    }

    this.loading = true
    let toastText = this.$t(
      'addDashboardElement.notification.removeRow.success'
    ).toString()
    let toastType = 'success'

    let result
    try {
      result = await (await getApp()).service('dashboard-row').remove(rowId)
    } catch (error) {
      toastText = this.$t(
        'addDashboardElement.notification.removeRow.error'
      ).toString()
      toastType = 'error'

      throw error
    } finally {
      this.toggleToast({
        text: toastText,
        type: toastType,
        btnText: '',
        data: {}
      })
      this.loading = false
    }

    if (result && result[0]) {
      this.$emit('remove-dashboard-row', result[0])
    }
  }
}
