
import { Component, Vue, Prop } from 'vue-property-decorator'
import { DataObject } from './DataObject.interface'
import { ValueTypes } from '@/components/WidgetEditor/WidgetEditor.schema'

/**
 * Fortschritts-Kreis
 */
@Component({})
export default class ProgressCircle extends Vue {
  /**
   * Aktuell verwendete Anzeige-Modus. Je nach verwendeten Modus, wird vom
   * Property [[`this.data`]] bei dem angegebenen Objekt andere Eigenschaften
   * erwartet bzw. vorausgesetzt.
   */
  @Prop({
    required: true,
    validator: (value: unknown): boolean =>
      value === 'completed' || value === 'range'
  })
  public mode!: 'completed' | 'range'

  /**
   * Daten die zur Berechnung des Fortschritts benötigt werden. Die erwarteten
   * Eigenschaften des Objektes sind vom Anzeige-Modus [[`this.mode`]] abhängig.
   * Vorausgesetzt sind folgende Eigenschaften, bei dem jeweiligen Modus:
   *
   * | Anzeige-Modus ([[`this.mode`]]) | Objekt-Eigenschaften         |
   * | ------------------------------- | ---------------------------- |
   * | `completed`                     | `incomplete`, `complete`     |
   * | `range`                         | `fromValue`, `toValue`       |
   */
  @Prop({ type: Object, required: true })
  public data!: DataObject

  /**
   * Durchmesser des Fortschritt-Kreises in px
   */
  @Prop({ type: Number, required: false, default: 150 })
  public progressSize!: number

  /**
   * Breite der Fortschritts-Kreis in px
   */
  @Prop({ type: Number, required: false, default: 20 })
  public progressWidth!: number

  /**
   * Farbe für den Fortschritts-Kreis
   */
  @Prop({ type: String, required: false })
  public color?: string

  /**
   * Gibt die Farbe des Kreises zurück, abhängig vom Darkmode.
   *
   * @returns Farbcode.
   */
  public get usedColor(): string {
    // Wenn eine Farbe angegeben wurde, wird diese verwendet.
    if (this.color) {
      return this.color
    }

    // Im Dark-Mode erscheint der Kreis in weiss.
    if (this.$vuetify.theme.dark) {
      return '#FFFFFF'
    }

    // Im Light Mode erscheint der Kreis in schwarz.
    return '#000000'
  }

  /**
   * Dreht den Startpunkt des Fortschrittskreises in Grad.
   */
  @Prop({ type: Number, required: false, default: 270 })
  public rotate!: number

  /**
   * Typ des Zählers des dargestellten Bruchs
   */
  @Prop({ type: String, required: false })
  public numeratorType?: string

  /**
   * Typ des Nenners des dargestellten Bruchs
   */
  @Prop({ type: String, required: false, default: ValueTypes.Total })
  public denominatorType?: String

  /**
   * Wert der aktuell erledigten Punkte
   */
  public currentValue = 0

  /**
   * Fortschritt in Prozent
   */
  public progress = 0

  /**
   * Summierung der nicht erledigten und erledigten Punkte
   */
  public total = 0

  /**
   * Zähler des dargestellten Bruchs
   */
  public numerator = 0

  /**
   * Nenner des dargestellten Bruchs
   */
  public denominator = 0

  /**
   * Setzt die neuen Werte und berechnet den Fortschritt, wenn der Anzeige-Modus
   * `completed` ist.
   */
  protected calculateByModeCompleted(): void {
    const { complete, incomplete, totalFilter } = this.data

    if (typeof complete === 'undefined' || typeof incomplete === 'undefined') {
      throw new Error(
        `received wrong data - properties 'complete' and 'incomplete' required`
      )
    }

    const maxValue = typeof incomplete === 'number' ? incomplete : 0 // offen
    this.currentValue = typeof complete === 'number' ? complete : 0 // erledigt

    //total
    if (typeof totalFilter !== undefined && totalFilter === true) {
      this.total = maxValue
    } else {
      this.total = maxValue + this.currentValue
    }

    if (this.numeratorType === ValueTypes.Open) {
      this.numerator = maxValue
      if (this.denominatorType === ValueTypes.Done) {
        // offen/erledigt
        this.denominator = this.currentValue
      } else if (this.denominatorType === ValueTypes.Total) {
        // offen/gesamt
        this.denominator = this.total
      } else {
        // offen/offen
        this.denominator = maxValue
      }
    } else if (this.numeratorType === ValueTypes.Done) {
      this.numerator = this.currentValue
      if (this.denominatorType === ValueTypes.Open) {
        // erledigt/offen
        this.denominator = maxValue
      } else if (this.denominatorType === ValueTypes.Total) {
        // erledigt/gesamt
        this.denominator = this.total
      } else {
        // erledigt/erledigt
        this.denominator = this.currentValue
      }
    } else if (this.numeratorType === ValueTypes.Total) {
      this.numerator = this.total
      if (this.denominatorType === ValueTypes.Open) {
        // gesamt/offen
        this.denominator = maxValue
      } else if (this.denominatorType === ValueTypes.Done) {
        // gesamt/erledigt
        this.denominator = this.currentValue
      } else {
        // gesamt/gesamt
        this.denominator = this.total
      }
    } else {
      // Fallback, bei Fehlern: offen/gesamt
      this.numerator = maxValue
      this.denominator = this.total
    }

    if (this.total === 0) {
      this.progress = 100
    } else {
      this.progress = Math.floor((this.numerator / this.denominator) * 100)
    }
  }

  /**
   * Setzt die neuen Werte und berechnet den Fortschritt, wenn der Anzeige-Modus
   * `range` ist.
   */
  protected calculateByModeRange(): void {
    const { fromValue, toValue } = this.data

    if (typeof fromValue === 'undefined' || typeof toValue === 'undefined') {
      throw new Error(
        `received wrong data - properties 'fromValue' and 'toValue' required`
      )
    }

    this.currentValue = typeof fromValue === 'number' ? fromValue : 0
    this.total = typeof toValue === 'number' ? toValue : 0

    this.numerator = this.currentValue
    this.denominator = this.total

    if (this.total === 0) {
      this.progress = 100
    } else if (this.currentValue >= this.total) {
      this.progress = 100
    } else {
      this.progress = Math.floor(
        ((this.total - this.currentValue) / this.total) * 100
      )
    }
  }

  /**
   * Bei Änderung an der Property [[this.data]] werden die neuen Werte den
   * entsprechenden Variablen übergeben und der Fortschritt berechnet.
   *
   * @returns Ist [[this.data]] ein leeres Objekt, ohne Eigenschaft, `true`,
   * sonst `false` - also, ob das Laden und Checken der Daten abgeschlossen ist.
   */
  public get checkData(): boolean {
    if (Object.keys(this.data).length === 0) {
      return true
    }

    if (this.mode === 'completed') {
      this.calculateByModeCompleted()
    } else {
      this.calculateByModeRange()
    }

    return false
  }
}
