import { WidgetSize } from '@/constants/widgetSize.enum'

/**
 * Mögliche Widget-Typen.
 */
export enum WidgetTypes {
  ProgressCircle = 1,
  ActionButton,
  ProgressCircleFromTo,
  KeyNumber
}

/**
 * Mögliche Typen für die Anzeige der Daten des Widgets
 */
export enum ValueTypes {
  Open = '1',
  Done = '2',
  Total = '3'
}

/**
 * Die Namen der möglichen Widget-Typen.
 */
export type WidgetTypeNames = keyof typeof WidgetTypes

/**
 * Eine Widget-Option.
 */
export interface WidgetOption {
  /**
   * Name der Option.
   */
  option: string
  /**
   * Type der Option.
   */
  type: string
}

/**
 * Eine Widget-Filter.
 */
export interface WidgetFilter {
  /**
   * Name des Filters.
   */
  filter: string
  /**
   * Typ des Filters.
   */
  type: string
}

/**
 * Ein Widget-Typ und die dazugehörigen Optionen.
 */
export class WidgetType {
  /**
   * Die ID des Widget-Typen.
   */
  public id!: WidgetTypes

  /**
   * Der Name des Widget-Typen.
   */
  public title!: WidgetTypeNames

  /**
   * Verfügbare Options eines Widgets.
   */
  public options?: WidgetOption[]

  /**
   * IDs der Queries, die auf Grund Ihres Ergebnisses für dieses Widget
   * verwendet werden können.
   */
  public availableQueryIds?: number[]

  /**
   * Der Name des Widgettypen, der in der Oberfläche angezeigt wird.
   * Name ist translationsgebunden.
   */
  public displayName?: string
}

/**
 * Eine Abfrage, die in einem Widget verwendet werden kann.
 */
export class WidgetQuery {
  /**
   * interne ID der Query aus den Metadata-Daten.
   */
  public id!: number

  /**
   * Der Name einer Query. Wird serverseitig zur Identifizierung einer Query
   * für den Query-Runner genutzt.
   */
  public name!: string

  /**
   * Der übersetzte Name einer Query.
   */
  public displayName!: string

  /**
   * Beschreibung der Query. Kommt aus der gewählten Sprache.
   */
  public desc?: string

  /**
   * Anwendbare Filter.
   */
  public filters?: WidgetFilter[]
}

/**
 * Beschreibt eine Aktion, die mit einem Klick auf ein Widget ausgeführt wird.
 */
export interface WidgetAction {
  /**
   * Name der Aktion.
   *
   * Wird verwendet, um einen Sprachstring zu ermitteln.
   */
  name: string

  /**
   * Pfad, der bei Klick auf das Widget angesteuert wird.
   */
  path?: string

  /**
   * ID der Action.
   *
   * Wird eine zusätzliche ID für den Bau des Pfades gebraucht, wird das mit
   * diesem Wert angezeigt.
   */
  idRequired?: boolean
}

/**
 * Die Widget-Metadaten.
 * Diese Daten enthalten alle Informationen über verfügbare Widgets und Queries.
 */
export interface WidgetMetadata {
  /**
   * Die verfügbaren WidgetTypen.
   */
  widgetTypes: WidgetType[]
  /**
   * Die verfügbaren Queries.
   */
  queries: WidgetQuery[]

  /**
   Die verfügbaren Actions, die ein Widget annehmen kann.
   */
  actions: WidgetAction[]
}

/**
 * Ein Widget, welches angelegt werden soll.
 */
export class WidgetDto {
  /** id - ID des Datensatzes */
  public id?: number

  /** title - Titel des Widgets */
  public title!: string

  /** size - Größe des Widgets */
  public size: WidgetSize = WidgetSize.Small

  /**
   * Typ des Widgets.
   */
  public widgetType: WidgetTypes = WidgetTypes.ProgressCircle

  /**
   * ID der Query, die die Daten für dieses Widget liefert.
   */
  public queryID?: number

  /**
   * ID der Dashboard-Row, an welche das Widget angehangen wird.
   */
  public rowID!: number

  /**
   * Definiert die Action, die hinter einem Widget liegen kann.
   * Diese Adresse wird angesteuert, sobald man das Widget anklickt.
   */
  public action = ''

  /**
   * Sortierreihenfolge
   */
  public defaultSortOrderAsc!: boolean

  /**
   * Aktiv oder Inaktiv
   */
  public active!: boolean

  /**
   * Übersetzungen des Widgettitels
   */
  public titleTranslations?: Translation[]
}

/**
 * Übersetzungsdatensatz
 */
export class Translation {
  /**
   * Sprachkürzel
   */
  public language?: string

  /**
   * Übersetzung
   */
  public translation?: string
}

/**
 * Widget mit ID und angehangenen IDs
 */
export class WidgetDtoExtended extends WidgetDto {
  /** id - ID des Datensatzes */
  public id = 0

  /** Optionen im  */
  public options?: Record<string, string>[] = []
}

/**
 * Optionen, die für die Widgeterstellung wichtig sind.
 */
export class WidgetOptionsDto {
  /**
   * ID der Option.
   */
  public id?: number

  /**
   * Schlüssel der Option.
   */
  public key!: string

  /**
   * Wert der Option.
   */
  public value!: string

  /**
   * ID des Widgets.
   */
  public dashboardWidgetID?: number

  /**
   * Übersetzungen des values
   */
  public valueTranslations?: Translation[]
}

/**
 * Filter, die die Queries in Verbindung mit Widgets einschränken.
 */
export class WidgetFilterDto {
  /**
   * ID des Filters.
   */
  public id?: number

  /**
   * Schlüssel des Filters.
   */
  public filter!: string

  /**
   * Wert des Filters.
   */
  public value!: string

  /**
   * ID des Widgets
   */
  public dashboardWidgetID?: number
}

/**
 * Dashboard Query.
 */
export class DashboardQueryDto {
  /**
   * ID des Datensatzes.
   */
  public id!: number

  /**
   * ID der zugehörigen Rolle.
   */
  public propertyID?: number | null

  /**
   * Gibt an, ob jeder Zugriff auf die Query haben soll.(unused!)
   */
  public systemDefault!: boolean

  /**
   * Gibt an, ob die Query einen einzelen Wert zurückgibt.(unused!)
   */
  public single!: boolean

  /**
   * Name der Query.
   */
  public name?: string
}

/**
 * Mögliche Unterweisungstypen in eplas.
 *
 * Enum-Werte sind durch eplas10l festgelegt.
 */
enum UWTyp {
  /**
   * Unterweisungen.
   */
  Instruction = 0,
  /**
   * Termine.
   */
  Appointment,
  /**
   * Dokumente.
   */
  Document,
  /**
   * SCORM
   */
  SCORM,
  /**
   * Vorsorgeuntersuchungen.
   */
  HealthcareAppointment
}

/**
 * Benutzerrolle für Aufgaben.
 */
enum TaskUserRole {
  /**
   * Verantwortlich, Ausfuehrender, Erfasser oder Beteiligter
   */
  All = 0,
  /**
   * Verantwortlich
   */
  ResponsibleOnly,
  /**
   * Ausfuehrender, Erfasser oder Beteiligter
   */
  Related,
  /**
   * Wirksamkeitsprüfer
   */
  EffectivenessChecker = 4
}

/**
 * mögliche Filterwerte für Queries.
 */
export interface WidgetQueryFilters {
  /**
   * Filter tovalue.
   */
  tovalue: number
  /**
   * Filter checklistvariableid.
   */
  checklistvariableid: number
  /**
   * Filter year.
   */
  year: number
  /**
   * Filter tovuwtypalue.
   */
  uwtyp: UWTyp
  /**
   * Filter showappointments.
   */
  showappointments: boolean
  /**
   * Filter showhealthcare.
   */
  showhealthcare: boolean
  /**
   * Filter taskUserRole.
   */
  taskUserRole: TaskUserRole
  /**
   * Filter category.
   */
  category: number
  /**
   * Filter thisyear.
   */
  thisyear: boolean
}

/**
 * Ein Item, welches in einem `v-select` verwendet werden kann.
 */
export interface DropdownItem {
  /**
   * Text des Items.
   */
  text: string
  /**
   * Value des Items.
   */
  value: boolean | number | string
}
