/**
 * Enum für die Widget-Größe (abhängig von der Bildschirmgröße)
 */
export enum WidgetSize {
  /**
   * Klein
   */
  Small = 1,

  /**
   * Mittel
   */
  Medium = 2,

  /**
   * Groß
   */
  Large = 3
}
