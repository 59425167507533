
import { Component, Mixins, Prop } from 'vue-property-decorator'
import Dark from '@/mixins/dark'
import Connectivity from '@/mixins/connectity.mixin'
import { getApp } from '@/helpers/feathers'
import ColumnCard from '@/components/ColumnCard/ColumnCard.vue'
import CustomDialog from '@/components/CustomDialog/CustomDialog.vue'
import { NotificationPayload } from '@/store/notifications/notification-payload.interface'
import { namespace } from 'vuex-class'
import { WidgetSize } from '@/constants/widgetSize.enum'
import { WidgetDto } from '../WidgetEditor/WidgetEditor.schema'
import WidgetEditor from '@/components/WidgetEditor/WidgetEditor.vue'

const NotificationStore = namespace('notification')

/**
 * Generisches Objekt für die Filter
 */
interface GenericObject {
  [key: string]: GenericObject | string | number | boolean | [] | undefined
}

/**
 * Widget-Grundrahmen für die verschiedenen Widgets des Benutzers
 * Verschiedene Informationen können in verschiedenen Darstellungsarten
 * angezeigt werden und die Skalierung und das Umbruchverhalten ist von der
 * Bildschirmgröße des Endgeräts abhängig (durch ColumnCard)
 */
@Component({
  components: {
    ColumnCard,
    CustomDialog,
    WidgetEditor
  }
})
export default class WidgetBasic extends Mixins(Dark, Connectivity) {
  /**
   * ID des Widgets
   */
  @Prop({ type: Number, required: true })
  public widgetId!: number

  /**
   * Text der Titelleiste des Widgets
   */
  @Prop({ type: String, required: true })
  public title!: string

  /**
   * Beschreibung des Widgets
   */
  @Prop({ type: String, required: false, default: '' })
  public description!: string

  /**
   * Widget-Größe (Breite, abhängig von der Bildschirmgröße)
   */
  @Prop({ type: Number, required: false, default: 1 })
  public size!: WidgetSize

  /**
   * Die Hintergundfarbe der Titelleiste des Widgets
   */
  @Prop({ type: String, required: false, default: 'primary' })
  public titleBackgroundColor!: string

  /**
   * Zentriert den Inhalt des Widgets mittig
   */
  @Prop({ type: Boolean, required: false, default: false })
  public centeredContent!: boolean

  /**
   * Aktion die für das Widget genutzt werden soll
   */
  @Prop({ type: String, required: false, default: null })
  public action!: string | null

  /**
   * Icon, welches in Titelleiste des Widgets angezeigt werden soll
   * Wenn leer, wird kein Icon verwendet
   */
  @Prop({ type: String, required: false, default: '' })
  public icon!: string

  /**
   * Entfernt das padding des Inhalts
   */
  @Prop({ type: Boolean, required: false, default: false })
  public noContentPadding!: boolean

  /**
   * Blendet bei true das Kontextmenü aus
   */
  @Prop({ type: Boolean, required: false, default: false })
  public noContextMenu!: boolean

  /**
   * ID für die zu verwendende Query
   */
  @Prop({ type: Number, required: false })
  public queryId?: number

  /**
   * Gibt an, ob das Widget aktiv oder inaktiv ist.
   */
  @Prop({ type: Boolean, required: false, default: true })
  public active?: boolean

  /**
   * Gibt an, ob das Widget aktiv oder inaktiv ist.
   */
  @Prop({ type: Boolean, required: false, default: false })
  public editMode!: boolean

  /**
   * Gibt an, ob sich das Widget noch im Ladevorgang befindet
   */
  public loading = false

  /**
   * Gibt an, ob der Bestätigungs-Dialog offen ist.
   */
  public confirmDialogOpen = false

  /**
   * Beinhaltet Daten welche für die Übergabe an Komponenten benötigt
   * werden, die ins WidgetBasic eingebunden werden
   */
  public data: object = {}

  /**
   * Beinhaltet die Filter des Widgets die an den Query-Service
   * übergeben werden sollen
   */
  @Prop({ type: Object, required: false })
  public filters?: GenericObject

  /**
   * Einstellung, ob zu offenen oder erledigten Terminen geprungen werden soll
   */
  @Prop({ type: String, required: false })
  public openOrDoneAppointments?: string

  /**
   * Startdatum für den Zeitfilter
   */
  @Prop({ type: String, required: false })
  public appointmentDate?: string

  /**
   * Funktion aus dem Vuex-Store, die den Toaster toggelt
   */
  @NotificationStore.Action('toggleToast')
  public toggleToast!: (payload: NotificationPayload) => void

  /**
   * Ruft beim Erstellen der Komponente die [this.setData] Funktion auf,
   * wenn eine QueryId übergeben wurde
   */
  public async created(): Promise<void> {
    if (this.queryId) {
      await this.setData()
    }
  }

  /**
   * Prüft `this.action` und ruft den entsprechenden Link auf.
   */
  public goToAction(): void {
    if (this.action === null || this.action === '') {
      return
    }

    let link = this.action.trim()

    if (link.indexOf('/') === 0) {
      const charArray = link.split('')
      charArray.shift()
      link = charArray.join('')
    }

    if (this.action === 'index.php/0/eplas?tabPreselected=4') {
      if (this.openOrDoneAppointments) {
        let openOrDone = parseInt(this.openOrDoneAppointments) - 1
        link += '&done=' + openOrDone
      }

      if (this.appointmentDate) {
        link += '&fromDate=' + this.appointmentDate
      }
    }

    if (link.indexOf('http') === 0) {
      window.location.assign(link)
    } else if (link.indexOf('index.php') === 0) {
      window.location.assign(`/${link}`)
    } else {
      this.$router.push({ path: `/${link}` })
    }
  }

  /**
   * Lässt sich die Daten vom Query-Runner anhand der übergebenen QueryId geben
   * und speichert diese zwischen.
   *
   * @param widget - widget, welches geupdatet wird
   * (bei Widget-Update nötig, um emit zu triggern)
   */
  public async setData(widget?: WidgetDto[]): Promise<void> {
    try {
      const query: GenericObject = {
        $widgetFilter: this.filters,
        $widgetID: this.widgetId
      }

      if (this.editMode) {
        query.$dashboardEditMode = true
      }

      let widgetQueryID
      if (widget) {
        widgetQueryID = widget[0].queryID
      }
      const result = await (await getApp())
        .service('query-runner')
        .get(widgetQueryID || this.queryId, {
          query
        })

      this.data = result.result

      if (widget) {
        this.$emit('widget-updated', widget)
      }
    } catch (error) {
      throw new Error(`${error}`)
    }
  }

  /**
   * Gibt die Anzahl an Spalten zurück, die das Widget je nach Größe einnehmen
   * soll
   *
   * @returns Anzahl der Spalten
   */
  public get cols(): number {
    if (this.size === WidgetSize.Small) {
      return 2
    }

    if (this.size === WidgetSize.Medium) {
      return 6
    }

    return 12
  }

  /**
   * Löscht ein Widget und emittet {{widget-removed}} zum DashboardViewer
   */
  public async removeWidget(): Promise<void> {
    let payload: NotificationPayload

    try {
      await (await getApp()).service('dashboard-widget').remove(this.widgetId)
      payload = {
        text: this.$t('widgetBasic.notification.remove.success').toString(),
        type: 'success'
      }
      this.$emit('widget-removed')
    } catch (error) {
      payload = {
        text: this.$t('widgetBasic.notification.remove.error').toString(),
        type: 'error'
      }
    }
    this.toggleToast(payload)
  }

  /**
   * Aktiviert oder deaktiviert ein Widget und emittet {{widget-updated}}.
   *
   * @returns geändertes Widget.
   */
  public async toggleActiveState(): Promise<void> {
    this.confirmDialogOpen = false

    try {
      const widget: WidgetDto = await (await getApp())
        .service('dashboard-widget')
        .patch(this.widgetId, { active: !this.active })

      this.$emit('widget-updated', widget)
    } catch (error) {
      this.toggleToast({
        text: this.$t('widgetBasic.notification.update.error').toString(),
        type: 'error'
      })
    }
  }

  /**
   * Wird ausgeführt, wenn ein Widget geupdated wurde.
   *
   * @param widget - Widgetdaten, die vom Widget Editor übergeben wurden.
   * @returns void.
   */
  public async widgetUpdate(widget: WidgetDto[]): Promise<void> {
    await this.setData(widget)
  }

  /**
   * Öffnet den Dialog und schließt das Kontext-Menü.
   *
   * @param openFunction - Funktion aus der Kinderkomponente zum Öffnen des
   * WidgetEditors.
   */
  public openWidgetEditor(openFunction: Function): void {
    openFunction()
    this.confirmDialogOpen = false
  }
}
