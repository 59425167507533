
import Dark from '@/mixins/dark'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import {
  WidgetQueryFilters,
  WidgetFilter,
  DropdownItem
} from '../WidgetEditor/WidgetEditor.schema'

/**
 * QueryFilters.
 *
 * Gibt die möglichen Filter einer Datenquelle zurück.
 */
@Component({})
export default class QueryFilters extends Mixins(Dark) {
  /**
   * Werte der Filter.
   */
  @Prop({ type: Object, required: false, default: () => {} })
  public value?: Partial<WidgetQueryFilters>

  /**
   * ID der Query.
   *
   * Ändert sich diese ID, wird `filterValues` geleert.
   */
  @Prop({ type: Number, required: false, default: undefined })
  public queryId?: number

  /**
   * Mögliche Filter, die mit an die Komponente übergeben werden.
   */
  @Prop({ type: Array, required: false, default: () => [] })
  public filterData?: WidgetFilter[]

  /**
   * Hält die ausgewählten Werte der Filter einer Query.
   */
  private filterValues: Partial<WidgetQueryFilters> = {}

  /**
   * Daten für den Filter `uwtyp`.
   *
   * @returns Daten für Dropdown.
   */
  private get filterUWTypData(): DropdownItem[] {
    return [
      {
        text: this.$t('queryFilters.filter.uwtyp.values.0').toString(),
        value: 0
      },
      {
        text: this.$t('queryFilters.filter.uwtyp.values.3').toString(),
        value: 3
      }
    ]
  }

  /**
   * Daten für den Filter `TaskUserRole`.
   *
   * @returns Daten für Dropdown.
   */
  private get filterTaskUserRoleData(): DropdownItem[] {
    return [
      {
        text: this.$t('queryFilters.filter.taskUserRole.values.0').toString(),
        value: 0
      },
      {
        text: this.$t('queryFilters.filter.taskUserRole.values.1').toString(),
        value: 1
      },
      {
        text: this.$t('queryFilters.filter.taskUserRole.values.2').toString(),
        value: 2
      },
      {
        text: this.$t('queryFilters.filter.taskUserRole.values.4').toString(),
        value: 4
      }
    ]
  }

  /**
   * Daten für den Filter `Reportable`.
   *
   * @returns Daten für Dropdown.
   */
  private get filterReportableData(): DropdownItem[] {
    return [
      {
        text: this.$t('queryFilters.filter.reportable.values.0').toString(),
        value: 0
      },
      {
        text: this.$t('queryFilters.filter.reportable.values.1').toString(),
        value: 1
      },
      {
        text: this.$t('queryFilters.filter.reportable.values.2').toString(),
        value: 2
      }
    ]
  }

  /**
   * Daten für boolische Filtertypen.
   *
   * @returns Daten für Dropdown.
   */
  private get filterBooleanData(): DropdownItem[] {
    return [
      {
        text: this.$t('queryFilters.general.values.true').toString(),
        value: true
      },
      {
        text: this.$t('queryFilters.general.values.false').toString(),
        value: false
      }
    ]
  }

  /**
   * Wählt die Datenquelle für Dropdowndaten anhand des Filters aus.
   *
   * @param item - ein Filter
   * @returns Dropdowndaten.
   */
  private dropdownData(item: WidgetFilter): DropdownItem[] {
    if (item.filter === 'uwtyp') {
      return this.filterUWTypData
    }

    if (item.filter === 'taskUserRole') {
      return this.filterTaskUserRoleData
    }

    if (item.filter === 'reportable') {
      return this.filterReportableData
    }

    if (item.type === 'boolean') {
      return this.filterBooleanData
    }

    return []
  }

  /**
   * Wenn sich die Query-ID ändert, werden die gemachten Filtereinstellungen
   * ungültig.
   */
  @Watch('queryId')
  private resetFilterValues() {
    this.filterValues = {}
  }

  /**
   * Ändern sich die Daten, dann werden diese an die Elternkomponente
   * übermittelt.
   */
  @Watch('filterValues')
  private emitValues() {
    this.$emit('input', this.filterValues)
  }

  /**
   * Created Function.
   */
  public created(): void {
    this.filterValues = this.value || {}
  }

  /**
   * Validationsregel für die Nummernfelder
   *
   * @returns Array von Validationsfunktionen
   */
  public get isPositiveIntegerRules(): ((v: string) => string | true)[] {
    return [
      (v: string): string | true => {
        return (
          !v ||
          (typeof v === 'number' && Number.isInteger(v) && v >= 0) ||
          (typeof v === 'string' &&
            parseInt(v).toString() === v &&
            parseInt(v) >= 0) ||
          this.$t('queryFilters.general.noNumber').toString()
        )
      }
    ]
  }
}
