
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import Dark from '@/mixins/dark'
import CustomDialog from '@/components/CustomDialog/CustomDialog.vue'
import { WidgetAction as IWidgetAction } from '@/components/WidgetEditor/WidgetEditor.schema'

/**
 * Widget Actions.
 *
 * Dient zur Auswahl einer Aktion, die bei einem Klick auf das Widget
 * ausgeführt wird.
 */
@Component({
  components: {
    CustomDialog
  }
})
export default class WidgetAction extends Mixins(Dark) {
  /**
   * Wert der Komponente.
   */
  @Prop({ type: String, required: false, default: '' })
  public value?: string

  /**
   * Wert der Aktion. Dieser Wert wird bei Abschluss an die Elternkomponente
   * weitergereicht.
   */
  public selectedValue?: string = ''

  /**
   * Label des Dropdowns / des Text-Feldes.
   */
  @Prop({ type: String, required: false })
  public label?: string

  /**
   * Hinweistext des Dropdowns / des Text-Feldes.
   */
  @Prop({ type: String, required: false })
  public hint?: string

  /**
   * Rules des Dropdowns / des Text-Feldes.
   */
  @Prop({ type: Array, required: false, default: () => [] })
  public rules?: Function[]

  /**
   * Actions, die als Vorauswahl zur Verfügung stehen. Werden vom
   * Metadaten-Service bereitgestellt.
   */
  @Prop({ type: Array, required: false, default: () => [] })
  public items?: IWidgetAction[]

  /**
   * Wenn `true`, dann wird das Textfeld anzeigt statt des Dropdowns.
   */
  public showEdit = false

  /**
   * Wenn `true`, wird ein Dialog angezeigt, welcher die zusätzlich benötigte ID
   * abfragt.
   */
  public showIdRequest = false

  /**
   * Die zusätzliche ID, die in die Action eingetragen wird.
   */
  private requestedId?: number = undefined

  /**
   * Kopiert `this.value` in eine lokale Variable um damit weiterzuarbeiten.
   */
  @Watch('value')
  public copyValue(): void {
    this.selectedValue = this.value
  }

  /**
   * Gibt den Sprachstring der Action zurück.
   *
   * @param name - Name der Action
   * @returns Actionname in der aktuell gewählten Sprache.
   */
  public actionName(name: string): string {
    const langStr = `widgetAction.actions.name.${name}`
    return !this.$te(langStr) ? name : this.$t(langStr).toString()
  }

  /**
   * Emitted die gewählte Aktion.
   */
  public emitValue(): void {
    this.$emit('input', this.selectedValue)
  }

  /**
   * Setzt die Variable `requestedId` zurück und leert die angefragte ID.
   */
  public resetRequestId(): void {
    this.showIdRequest = false
    this.requestedId = undefined
  }

  /**
   * Ersetzt den Platzhalter in der Action durch die eingegebene ID.
   */
  public idToAction(): void {
    this.selectedValue = (this.selectedValue || '').replace(
      '${0}',
      (this.requestedId || '').toString()
    )

    if (this.showIdRequest) {
      this.showEdit = true
    }

    this.resetRequestId()
    this.emitValue()
  }

  /**
   * Behandelt die Eingaben einer Action (Dropdown-Auswahl oder Eingabe ins
   * Textfeld).
   *
   * @param action - Actiondata
   */
  public inputHandler(action: IWidgetAction): void {
    if (typeof action === 'object') {
      this.selectedValue = action.path

      if (action.hasOwnProperty('idRequired') && action.idRequired) {
        this.showIdRequest = true
        return
      }

      this.resetRequestId()
      this.emitValue()
      return
    }

    if (typeof action === 'string') {
      this.emitValue()
    }
  }

  /**
   * Created Function.
   */
  public created(): void {
    this.copyValue()

    if (!this.selectedValue) {
      this.showEdit = false
      return
    }

    if (!(this.items || []).find(i => i.path === this.selectedValue)) {
      this.showEdit = true
    }
  }
}
