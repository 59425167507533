import { render, staticRenderFns } from "./AddDashboardElement.vue?vue&type=template&id=737e439e&scoped=true&"
import script from "./AddDashboardElement.vue?vue&type=script&lang=ts&"
export * from "./AddDashboardElement.vue?vue&type=script&lang=ts&"
import style0 from "./AddDashboardElement.vue?vue&type=style&index=0&id=737e439e&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "737e439e",
  null
  
)

export default component.exports