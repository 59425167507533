
import Dark from '@/mixins/dark'
import Connectivity from '@/mixins/connectity.mixin'
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'
import CustomDialog from '@/components/CustomDialog/CustomDialog.vue'
import { WidgetSize } from '@/constants/widgetSize.enum'
import { getApp } from '@/helpers/feathers'
import {
  WidgetMetadata,
  WidgetDto,
  WidgetQuery,
  WidgetFilter,
  WidgetTypes,
  WidgetOptionsDto,
  WidgetFilterDto,
  WidgetDtoExtended,
  WidgetType,
  WidgetQueryFilters,
  DashboardQueryDto,
  DropdownItem,
  ValueTypes,
  Translation
} from './WidgetEditor.schema'
import { NotificationPayload } from '@/store/notifications/notification-payload.interface'
import { namespace } from 'vuex-class'
import IconPicker from '@/components/IconPicker/IconPicker.vue'
import ColorPicker from '@/components/ColorPicker/ColorPicker.vue'
import QueryFilters from '@/components/QueryFilters/QueryFilters.vue'
import WidgetAction from '@/components/WidgetAction/WidgetAction.vue'
import MultiLanguageEditor from '@/components/MultiLanguageEditor/MultiLanguageEditor.vue'
import DatePicker from '../DatePicker/DatePicker.vue'

const NotificationStore = namespace('notification')

/**
 * WidgetEditor
 */
@Component({
  components: {
    BaseFrame,
    CustomDialog,
    IconPicker,
    ColorPicker,
    QueryFilters,
    WidgetAction,
    MultiLanguageEditor,
    DatePicker
  }
})
export default class WidgetEditor extends Mixins(Dark, Connectivity) {
  /**
   * Funktion aus dem Vuex-Store, die den Toaster toggelt
   */
  @NotificationStore.Action('toggleToast')
  public toggleToast!: (payload: NotificationPayload) => void

  /**
   * ID des Widgets, welches bearbeitet werden soll.
   *
   * @param rowId - ID der Dashboard Row
   */
  @Prop({ type: Number, required: false })
  public widgetId?: number

  /**
   * Wenn eine Widget-ID übergeben wird, so ist der editMode aktiv.
   *
   * @returns boolischen Wert.
   */
  public get editMode(): boolean {
    return !!this.widgetId
  }

  /**
   * Die RowId, in dem das Widget aufgehangen ist.
   *
   * @param rowId - ID der Dashboard Row
   */
  @Prop({ type: Number, required: false })
  public rowId?: number

  /**
   * Verfügbare Größen, die ein Widget annehmen kann.
   */
  public WidgetSize = WidgetSize

  /**
   * Verfügbare Widget-Typen.
   */
  public WidgetTypes = WidgetTypes

  /**
   * Beinhaltet alle Metadaten, die vom Server mitgeteilt werden.
   */
  public metadata: WidgetMetadata = {
    widgetTypes: [],
    queries: [],
    actions: []
  }

  /**
   * Indikator, ob der Widget Editor offen oder geschlossen ist.
   */
  public showDialog = false

  /**
   * Zeigt an, ob gerade ein Ladevorgang stattfindet.
   */
  private isLoading = false

  /**
   * Zeigt an, ob gerade ein Speichervorgang stattfindet.
   */
  private isSaving = false

  /**
   * Funktion, welche an den Date-Picker übergeben wird,
   * um nur die Eingabe von Daten,
   * welche in der Vergangenheit liegen, zuzulassen
   *
   * @param dateString - zu prüfendes Datum als String
   * @returns true, wenn das Datum in der vergangenheit liegt, false wenn nicht
   */
  public allowedDates(dateString: string): boolean {
    return dateString < new Date().toISOString().substring(0, 10)
  }

  /**
   * Öffnet den Dialog. Funktion wird als slot-Prop an die Elternkomponente
   * gegeben, damit man den Dialog von außen öffnen kann.
   */
  public openDialog(): void {
    this.showDialog = true
  }

  /**
   * Indikator, ob alle Felder korrekt ausgefüllt sind.
   */
  public valid = false

  /**
   * Daten des neuen Widgets
   */
  public widget: WidgetDto = new WidgetDto()

  /**
   * Hält die ausgewählte Query.
   */
  public selectedQuery?: WidgetQuery = new WidgetQuery()

  /**
   * Mögliche Optionen für das neue Widget.
   */
  public options: { [key: string]: string } = {
    description: '',
    icon: '',
    titleBackgroundColor: '',
    actionButtonIcon: '',
    actionButtonIconColor: '',
    actionButtonLabel: '',
    progressCircleColor: '',
    numerator: ValueTypes.Open,
    denominator: ValueTypes.Total,
    openOrDoneAppointments: ValueTypes.Open,
    appointmentDate: ''
  }

  /**
   * Übersetzungen der Beschreibung des Widgets
   */
  public descriptionTranslations?: Translation[] = []

  /**
   * Übersetzungen der Beschreibung der Schaltfläche des Widgets
   */
  public actionButtonLabelTranslations?: Translation[] = []

  /** Mögliche Filter für das Widget. */
  public filters: Partial<WidgetQueryFilters> = {}

  /**
   * Created-Funktion.
   * Wird aufgeführt, wenn die Komponente lädt.
   */
  public async created(): Promise<void> {
    try {
      const result: WidgetMetadata = await (await getApp())
        .service('dashboard-widget-metadata')
        .find()

      this.metadata = result
    } catch (error) {
      throw error
    }
  }

  /**
   * Wird der Dialog geöffnet, werden die Daten geladen.
   */
  @Watch('showDialog')
  public async loadDataOnOpen(): Promise<void> {
    if (this.showDialog) {
      if (this.editMode) {
        this.isLoading = true
        const widget: WidgetDto = await (await getApp())
          .service('dashboard-widget')
          .get(this.widgetId, {
            query: {
              $dashboardEditMode: true
            }
          })

        if (!widget) {
          this.toggleToast({
            text: 'widget not found!',
            type: 'error'
          })

          throw Error('widget not found!')
        }

        this.widget = widget

        if (this.widget.titleTranslations) {
          this.widget.titleTranslations.forEach(translation => {
            if (
              translation.language === this.$i18n.locale &&
              typeof translation.translation === 'string'
            ) {
              this.widget.title = translation.translation
            }
          })
        }

        if (this.widget.queryID) {
          const query = (await (await getApp())
            .service('dashboard-query')
            .get(this.widget.queryID)) as DashboardQueryDto

          if (query) {
            this.selectedQuery = this.queries.find(
              ({ name }) => name === query.name
            )
          }
        }

        const options = await (await getApp())
          .service('dashboard-widget-options')
          .find({
            query: {
              $widgetID: this.widget.id
            }
          })

        if (options) {
          if ('descriptionTranslations' in options) {
            this.descriptionTranslations = options.descriptionTranslations
            delete options.descriptionTranslations
          }
          if ('actionButtonLabelTranslations' in options) {
            this.actionButtonLabelTranslations =
              options.actionButtonLabelTranslations
            delete options.actionButtonLabelTranslations
          }
          this.options = options
        }

        if (this.descriptionTranslations) {
          this.descriptionTranslations.forEach(translation => {
            if (
              translation.language === this.$i18n.locale &&
              typeof translation.translation === 'string'
            ) {
              this.options.description = translation.translation
            }
          })
        }

        if (this.actionButtonLabelTranslations) {
          this.actionButtonLabelTranslations.forEach(translation => {
            if (
              translation.language === this.$i18n.locale &&
              typeof translation.translation === 'string'
            ) {
              this.options.actionButtonLabel = translation.translation
            }
          })
        }

        if (
          typeof this.options.numerator !== 'string' ||
          this.options.numerator.trim() === ''
        ) {
          this.options.numerator = ValueTypes.Open
        }
        if (
          typeof this.options.denominator !== 'string' ||
          this.options.denominator.trim() === ''
        ) {
          this.options.denominator = ValueTypes.Total
        }

        if (
          typeof this.options.openOrDoneAppointments !== 'string' ||
          this.options.openOrDoneAppointments.trim() === ''
        ) {
          this.options.openOrDoneAppointments = ValueTypes.Open
        }

        const filters = await (await getApp())
          .service('dashboard-widget-filters')
          .find({
            query: {
              $widgetID: this.widget.id
            }
          })

        if (filters) {
          this.filters = filters
        }

        this.isLoading = false
      }
    } else {
      this.clearForm()
    }
  }

  /**
   * Gleicht den Eintrag im "Titel"-Feld aus dem WidgetEditor und den
   * entsprechenden Eintrag im Mehrsprachigkeits-Editor an
   */
  @Watch('widget.title')
  public changedTitle(): void {
    if (this.widget.titleTranslations) {
      const tl = this.widget.titleTranslations.find(
        translation => translation.language === this.$i18n.locale
      )
      if (tl) {
        const index = this.widget.titleTranslations.indexOf(tl)
        this.widget.titleTranslations[index].translation = this.widget.title
      } else {
        this.widget.titleTranslations.push({
          language: this.$i18n.locale,
          translation: this.widget.title
        })
      }
    } else {
      this.widget.titleTranslations = []
      this.widget.titleTranslations.push({
        language: this.$i18n.locale,
        translation: this.widget.title
      })
    }
  }

  /**
   * Gleicht den Eintrag im "Beschreibung"-Feld aus dem WidgetEditor und den
   * entsprechenden Eintrag im Mehrsprachigkeits-Editor an
   */
  @Watch('options.description')
  public changedDescription(): void {
    if (this.descriptionTranslations) {
      const tl = this.descriptionTranslations.find(
        translation => translation.language === this.$i18n.locale
      )
      if (tl) {
        const index = this.descriptionTranslations.indexOf(tl)
        this.descriptionTranslations[index].translation =
          this.options.description
      } else {
        this.descriptionTranslations.push({
          language: this.$i18n.locale,
          translation: this.options.description
        })
      }
    } else {
      this.descriptionTranslations = []
      this.descriptionTranslations.push({
        language: this.$i18n.locale,
        translation: this.options.description
      })
    }
  }

  /**
   * Gleicht den Eintrag im "Schaltfläche Label"-Feld aus dem WidgetEditor und
   * den entsprechenden Eintrag im Mehrsprachigkeits-Editor an
   */
  @Watch('options.actionButtonLabel')
  public changedActionButtonLabel(): void {
    if (this.actionButtonLabelTranslations) {
      const tl = this.actionButtonLabelTranslations.find(
        translation => translation.language === this.$i18n.locale
      )
      if (tl) {
        const index = this.actionButtonLabelTranslations.indexOf(tl)
        this.actionButtonLabelTranslations[index].translation =
          this.options.actionButtonLabel
      } else {
        this.actionButtonLabelTranslations.push({
          language: this.$i18n.locale,
          translation: this.options.actionButtonLabel
        })
      }
    } else {
      this.actionButtonLabelTranslations = []
      this.actionButtonLabelTranslations.push({
        language: this.$i18n.locale,
        translation: this.options.actionButtonLabel
      })
    }
  }

  /**
   * Öffnet den Mehrsprachigkeits-Editor
   *
   * @param openFunction - Funktion aus der Kinderkomponente zum Öffnen des
   * Mehrsprachigkeits-Editors.
   */
  public openMLE(openFunction: Function): void {
    if (this.widget.titleTranslations) {
      this.widget.titleTranslations.forEach(translation => {
        if (translation.language === this.$i18n.locale) {
          translation.translation = this.widget.title
        }
      })
    } else {
      this.widget.titleTranslations = []
      const translation = {
        language: this.$i18n.locale,
        translation: this.widget.title
      }
      this.widget.titleTranslations.push(translation)
    }
    openFunction()
  }

  /**
   * Gibt die möglichen Queries anhand des gewählten Widget-Typen zurück. Das
   * Ergebnis wird sortiert.
   *
   * @returns Queries
   */
  public get queries(): WidgetQuery[] {
    const widgetType = this.metadata.widgetTypes.find(
      type => type.id === this.widget.widgetType
    )

    if (!widgetType || !widgetType.availableQueryIds) {
      return []
    }

    const queries = this.metadata.queries.filter(q =>
      (widgetType.availableQueryIds || []).includes(q.id)
    )

    if (queries.length > 0) {
      queries.forEach((query: WidgetQuery) => {
        const titleTranslation = `widgetEditor.queries.${query.id}.title`
        const descTranslation = `widgetEditor.queries.${query.id}.description`

        query.displayName = this.$te(titleTranslation)
          ? this.$t(titleTranslation).toString()
          : query.name
        query.desc = this.$te(descTranslation)
          ? this.$t(descTranslation).toString()
          : ''
      })
    }

    return queries.sort((a, b) =>
      (a.displayName || '').localeCompare(b.displayName || '')
    )
  }

  /**
   * Gibt die möglichen Filter für die gewählte Query aus den Metadaten zurück.
   *
   * @returns Array von WidgetFiltern.
   */
  public get filterData(): WidgetFilter[] {
    if (!this.selectedQuery) {
      return []
    }

    return this.selectedQuery.filters || []
  }

  /**
   * Gibt die Beschreibung der gewählten Query zurück.
   *
   * @returns Querybeschreibung.
   */
  public get queryDescription(): string {
    if (!this.selectedQuery) {
      return ''
    }

    const { id } = this.selectedQuery

    const translation = `widgetEditor.queries.${id}.description`

    return this.$te(translation) ? this.$t(translation).toString() : ''
  }

  /**
   * Gibt die verfügbaren Widget-Typen zurück.
   *
   * @returns Widgettypen.
   */
  public get widgetTypes(): WidgetType[] {
    if (!this.metadata.widgetTypes) {
      return []
    }

    return this.metadata.widgetTypes.map((wt: WidgetType) => {
      const nameTranslation = `widgetEditor.types.${wt.title}.name`

      wt.displayName = this.$te(nameTranslation)
        ? this.$t(nameTranslation).toString()
        : wt.title

      return wt
    })
  }

  /**
   * Gibt die Beschreibung des gewählten WidgetTypes aus.
   *
   * @returns Beschreibungstext.
   */
  public get widgetTypeDescription(): string | undefined {
    if (this.widget.widgetType <= 0) {
      return
    }

    const wt = this.metadata.widgetTypes.find(
      a => a.id === this.widget.widgetType
    )

    if (!wt) {
      return
    }

    const translation = `widgetEditor.types.${wt.title}.description`

    return this.$te(translation) ? this.$t(translation).toString() : undefined
  }

  /**
   * Daten für die Anzeige von offen/erledigt
   *
   * @returns Daten für Dropdown
   */
  public get openDoneData(): DropdownItem[] {
    return [
      {
        text: this.$t('dashboardViewer.widgetDescription.open').toString(),
        value: '1'
      },
      {
        text: this.$t('dashboardViewer.widgetDescription.done').toString(),
        value: '2'
      }
    ]
  }

  /**
   * Daten für die Anzeige von gesammt
   *
   * @returns Daten für Dropdown
   */
  public get totalData(): DropdownItem[] {
    return [
      {
        text: this.$t('dashboardViewer.widgetDescription.total').toString(),
        value: '3'
      }
    ]
  }

  /**
   * Liefert anhand der ausgewählten Query und der RowID des Widgets die
   * passende QueryId aus `DashboardQueries`. Wenn kein Datensatz mit dem
   * Querynamen und der ermittelten Rollen-ID vorliegt, wird diese angelegt.
   *
   * @returns QueryId aud der DB oder undefined
   */
  private async createOrGetQueryId(): Promise<number | undefined> {
    if (!this.selectedQuery) {
      return
    }

    const { name } = this.selectedQuery

    const query = (await (await getApp()).service('dashboard-query').create(
      {
        propertyID: null,
        systemDefault: false,
        single: false,
        name
      } as DashboardQueryDto,
      {
        query: {
          $rowId: this.widget.rowID
        }
      }
    )) as DashboardQueryDto

    return query.id
  }

  /**
   * Speichert die gemachten Eingaben zum Widget in der Datenbank.
   *
   * @returns das Widget mit den gespeicherten Daten
   */
  public async insertWidget(): Promise<WidgetDto> {
    if (!this.rowId) {
      throw new Error('no rowId!')
    }

    this.formValidate()

    this.widget.rowID = this.rowId
    this.widget.defaultSortOrderAsc = true
    this.widget.active = true
    this.widget.queryID = await this.createOrGetQueryId()

    let newWidget: WidgetDtoExtended[]

    try {
      newWidget = await (await getApp())
        .service('dashboard-widget')
        .create(this.widget)
    } catch (e) {
      const msg = this.$t('widgetEditor.notification.insert.error').toString()

      this.toggleToast({
        text: msg,
        type: 'error'
      })

      throw new Error(msg)
    }

    await this.insertFiltersForWidget(newWidget[0].id)

    const savedOptions = (
      await this.insertOptionsForWidget(newWidget[0].id)
    ).map(o => [o.key, JSON.parse(o.value)])

    newWidget[0].options = Object.fromEntries(savedOptions || [])

    this.$emit('widgetInserted', newWidget[0])

    this.clearForm()
    this.showDialog = false

    this.toggleToast({
      text: this.$t('widgetEditor.notification.insert.success').toString(),
      type: 'success'
    })

    return newWidget[0]
  }

  /**
   * Speichert die gemachten Eingaben zum Widget in der Datenbank.
   */
  private async updateWidget(): Promise<void> {
    if (!this.widget.id) {
      throw new Error('no widget ID!')
    }

    this.formValidate()

    let updatedWidget: WidgetDtoExtended[]

    this.isSaving = true

    try {
      const { id: widgetID, ...updateData } = this.widget
      updateData.queryID = await this.createOrGetQueryId()
      updatedWidget = await (await getApp())
        .service('dashboard-widget')
        .patch(widgetID, updateData)
    } catch (e) {
      const msg = this.$t('widgetEditor.notification.update.error').toString()

      this.toggleToast({
        text: msg,
        type: 'error'
      })

      this.isSaving = false

      throw new Error(msg)
    }

    await (await getApp()).service('dashboard-widget-options').remove(null, {
      query: {
        $widgetID: this.widget.id
      }
    })

    const newOptions = await this.insertOptionsForWidget(this.widget.id)

    const savedOptions = newOptions.map(o => [o.key, JSON.parse(o.value)])
    updatedWidget[0].options = Object.fromEntries(savedOptions || [])

    await (await getApp()).service('dashboard-widget-filters').remove(null, {
      query: {
        $widgetID: this.widget.id
      }
    })

    await this.insertFiltersForWidget(this.widget.id)

    this.$emit('widgetUpdated', updatedWidget)

    this.clearForm()
    this.showDialog = false
    this.isSaving = false

    this.toggleToast({
      text: this.$t('widgetEditor.notification.update.success').toString(),
      type: 'success'
    })
  }

  /**
   * Speichert die Filtereinstellungen für das Widget.
   *
   * @param dashboardWidgetID - WidgetID
   * @returns gespeicherte Filtereinstellungen.
   */
  private async insertFiltersForWidget(
    dashboardWidgetID: number
  ): Promise<WidgetFilterDto[]> {
    const filters: WidgetFilterDto[] = []

    if (!this.selectedQuery) {
      return Promise.all([])
    }

    const { filters: selectedQueryFilters } = this.selectedQuery

    if (selectedQueryFilters) {
      const allowedFilters = (selectedQueryFilters || []).map(f => f.filter)

      for (const filter in this.filters) {
        if (
          allowedFilters.includes(filter) &&
          this.filters.hasOwnProperty(filter) &&
          this.filters[filter as keyof WidgetQueryFilters] !== undefined
        ) {
          filters.push({
            dashboardWidgetID,
            filter,
            value: JSON.stringify(
              this.filters[filter as keyof WidgetQueryFilters]
            )
          })
        }
      }
    }

    const service = (await getApp()).service('dashboard-widget-filters')

    const promises = filters.map(async f => {
      const a = await service.create(f)
      return a[0]
    })

    return Promise.all(promises)
  }

  /**
   * Sammelt die Optionsdaten für die Widgetoptions.
   *
   * @param dashboardWidgetID - ID des Widgets
   * @returns Array von Options, die in die Datenbank eingetragen werden können.
   */
  private async insertOptionsForWidget(
    dashboardWidgetID: number
  ): Promise<WidgetOptionsDto[]> {
    const options: WidgetOptionsDto[] = []

    const metadata = this.metadata.widgetTypes.find(
      ({ id }) => id === this.widget.widgetType
    )

    if (metadata) {
      const allowedOptions = (metadata.options || []).map(o => o.option)

      for (const opt in this.options) {
        if (allowedOptions.includes(opt) && this.options[opt]) {
          if (opt === 'description') {
            options.push({
              dashboardWidgetID,
              key: opt,
              value: this.options[opt],
              valueTranslations: this.descriptionTranslations
            })
          } else if (opt === 'actionButtonLabel') {
            options.push({
              dashboardWidgetID,
              key: opt,
              value: this.options[opt],
              valueTranslations: this.actionButtonLabelTranslations
            })
          } else {
            options.push({
              dashboardWidgetID,
              key: opt,
              value: this.options[opt]
            })
          }
        }
      }
    }

    options.forEach(option => (option.value = JSON.stringify(option.value)))

    const service = (await getApp()).service('dashboard-widget-options')

    const promises = options.map(async option => {
      const a = await service.create(option)
      return a[0]
    })

    return Promise.all(promises)
  }

  /**
   * Speichert die über den Mehrsprachigkeitseditor gemachten Änderungen
   * an den Übersetzungen im Widget
   *
   * @param translations - Array mit den geänderten Übersetzzungsdaten
   * @param field - Feld, für welches die Übersetzungen angepasst werden sollen
   */
  public translationsAdded(translations: Translation[], field: string): void {
    switch (field) {
      case 'title':
        this.widget.titleTranslations = translations

        const newTitle = this.widget.titleTranslations.find(
          translation => translation.language === this.$i18n.locale
        )
        if (newTitle && newTitle.translation) {
          this.widget.title = newTitle.translation
          this.$forceUpdate()
        }
        break
      case 'description':
        this.descriptionTranslations = translations

        const newDescription = this.descriptionTranslations.find(
          translation => translation.language === this.$i18n.locale
        )
        if (newDescription && newDescription.translation) {
          this.options.description = newDescription.translation
          this.$forceUpdate()
        }
        break
      case 'actionButtonLabel':
        this.actionButtonLabelTranslations = translations

        const newActionButtonLabel = this.actionButtonLabelTranslations.find(
          translation => translation.language === this.$i18n.locale
        )
        if (newActionButtonLabel && newActionButtonLabel.translation) {
          this.options.actionButtonLabel = newActionButtonLabel.translation
          this.$forceUpdate()
        }
        break
      default:
        throw new Error(`Could not add translations for field ${field}`)
        break
    }
  }

  /**
   * Setzt die gemachten Eingaben im Form zurück.
   */
  private clearForm() {
    this.widget = new WidgetDto()
    this.selectedQuery = undefined

    for (const key in this.options) {
      this.options[key] = ''
    }

    this.resetFormValidation()
  }

  /**
   * Entfernt die QueryID, falls der WidgetTyp geändert wird.
   */
  @Watch('widget.widgetType')
  private clearQuery(): void {
    if (!this.isLoading) {
      this.selectedQuery = undefined
    }
  }

  /**
   * Wird ausgeführt, wenn man den Dialog des Widget-Editors schließt,
   * ohne ein neues Widget angelegt zu haben.
   */
  public cancelDialog(): void {
    this.resetFormValidation()
    this.showDialog = false
  }

  /**
   * Prüft, ob alle Felder innerhalb des Formulars valide sind.
   * Falls nicht, wird eine Meldung ausgegeben und ein Error geworfen.
   */
  private formValidate(): void {
    const form = this.$refs.form as HTMLFormElement
    const valid = form.validate()

    if (!valid) {
      throw new Error('validation failed.')
    }
  }

  /**
   * Resettet die Formvalidation.
   */
  private resetFormValidation(): void {
    const form = this.$refs.form as HTMLFormElement
    form.resetValidation()
  }

  /**
   * Validationsregel für den Titel.
   *
   * @returns Array von Validationsfunktionen
   */
  public get nameRules(): ((v: string) => string | true)[] {
    return [
      (v: string): string | true =>
        !!v || this.$t('widgetEditor.fields.title.validationError').toString()
    ]
  }

  /**
   * Validationsregel für den die Auswahl der Query.
   * Datenquelle muss ausgewählt sein, wenn bestimmte Widgettypen ausgewählt
   * wurden.
   *
   * @returns Array von Validationsfunktionen
   */
  public get queryRules(): ((v: number) => string | true)[] {
    return [
      (v: number): string | true => {
        if (this.widget.widgetType === WidgetTypes.ActionButton) {
          return true
        }

        const validationError = this.$t(
          'widgetEditor.fields.query.validationError'
        ).toString()

        return !!v || validationError
      }
    ]
  }

  /**
   * Validationsregel für die Action.
   *
   * @returns Array von Validationsfunktionen
   */
  public get actionRules(): ((v: string) => string | true)[] {
    return [
      (v: string): string | true => {
        if (this.widget.widgetType !== WidgetTypes.ActionButton) {
          return true
        }

        return (
          !!v ||
          this.$t('widgetEditor.fields.action.validationError').toString()
        )
      }
    ]
  }

  /**
   * Validationsregel für das Action Icon.
   *
   * @returns Array von Validationsfunktionen
   */
  public get actionIconRules(): ((v: string) => string | true)[] {
    return [
      (v: string): string | true => {
        if (this.widget.widgetType !== WidgetTypes.ActionButton) {
          return true
        }

        return (
          !!v ||
          this.$t('widgetEditor.fields.actionIcon.validationError').toString()
        )
      }
    ]
  }

  /**
   * Validationsregel für die  Auswahl der im Widget dargestellten Werte
   *
   * @returns Array von Validationsfunktionen
   */
  public get openDoneTotalRule(): ((v: string) => string | true)[] {
    return [
      (v: string): string | true => {
        return (
          !!v ||
          this.$t(
            'dashboardViewer.widgetDescription.validationError'
          ).toString()
        )
      }
    ]
  }
}
