
import { Component, Vue } from 'vue-property-decorator'
import WidgetBasic from '@/components/WidgetBasic/WidgetBasic.vue'
import ActionButton from '@/components/ActionButton/ActionButton.vue'
import ProgressCircle from '@/components/ProgressCircle/ProgressCircle.vue'
import KeyNumber from '@/components/KeyNumber/KeyNumber.vue'
import DashboardViewer from '@/components/DashboardViewer/DashboardViewer.vue'
import BaseFrame from '@/components/BaseFrame/BaseFrame.vue'

/**
 * Dashboard-View.
 */
@Component({
  components: {
    WidgetBasic,
    ProgressCircle,
    ActionButton,
    DashboardViewer,
    KeyNumber,
    BaseFrame
  }
})
export default class SpielwieseWidgets extends Vue {
  /**
   * Beispielhafter langer Text für Widgets
   */
  public longText =
    'Dieser Text erklärt genaueres über das Widget und kann maximal zweizeilig werden.'

  /**
   * Beispielfunktion für console.log
   *
   * @param text - Text der geloggt wird
   */
  public showLog(text: string): void {
    console.log(text)
  }
}
