
import { Component, Vue, Prop } from 'vue-property-decorator'

/**
 * Button mit Text und Icon, der ein Klick-Event triggern kann
 */
@Component({})
export default class ActionButton extends Vue {
  /**
   * Icon des Buttons
   */
  @Prop({ type: String, required: false, default: '' })
  public icon?: string

  /**
   * Text des Buttons
   */
  @Prop({ type: String, required: false, default: '' })
  public label?: string

  /**
   * Farbe des Buttontext
   */
  @Prop({ type: String, required: false, default: '' })
  public labelColor?: string

  /**
   * Farbe des Buttonicons
   */
  @Prop({ type: String, required: false, default: '' })
  public iconColor?: string

  /**
   * Farbe des Buttons
   */
  @Prop({ type: String, required: false, default: '' })
  public color?: string

  /**
   * Baut aus dem color-prop den Namen der Vuetify Text-Farbklasse zusammen
   *
   * @returns Die Vuetify Klasse für die Text Farbe
   */
  public get textColor(): string {
    if (!this.labelColor) {
      return ''
    }
    return this.labelColor + '--text'
  }
}
